
<template>
    <div class="Bridge">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/Bridge/banner.png" alt="">
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>Bridge</h1>
                    <h2>Here're some bridges to transfer assets.</h2>
                    <h2>Please do your own research before using.</h2>
                    <div>
                        <a class="zkSyncEraPortal" href="https://zksync.io/explore#bridges" target="_block">
                            <img src="@/assets/Bridge/zkSyncEraPortal.png" alt="">
                            <span>zkSync Era Portal</span>
                        </a>
                        <a class="ScrollPortal" href="https://scroll.io/portal" target="_block">
                            <img src="@/assets/Bridge/ScrollPortal.png" alt="">
                            <span>Scroll Portal</span>
                        </a>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="migrationGuide">
            <div class="center connectBoxW">
                <div class="warp">
                    <h1>SPACE / xSPACE / Planet NFT Migration Guide</h1>
                    <h3>To distinguish between the assets of 1.0 and 2.0, SpaceFi 2.0 will issue a new token named STAR. It inherits the supply, distribution, etc., from SPACE (1.0) and will be exchanged at a 1:1 ratio with SPACE.</h3>
                    <a href="https://space-finance.medium.com/spacefi-2-0-product-update-migration-introduction-f551c3ddf4e0" target="_balck">Learn More >></a>
                </div>
            </div>
        </div>
        <div class="Migration">
            <div class="center connectBoxW">
                <div class="titlebox">
                    <h1>Migrate from SpaceFi 1.0 to 2.0</h1>
                    <p></p>
                </div>
                <div class="tabs">
                    <div>
                        <span :class="actIndex == 0 ? 'act' : ''" @click="actIndex = 0;selectNames = [];selectNFTIds = []">Planet NFT</span>
                    </div>
                    <div>
                        <span :class="actIndex == 2 ? 'act' : ''" @click="actIndex = 2;sendxSpaceNum = null">{{xtokenSymbol}} Token</span>
                    </div>
                    <div>
                        <span :class="actIndex == 1 ? 'act' : ''" @click="actIndex = 1;sendSpaceNum = null;">{{tokenSymbol}} Token</span>
                    </div>
                    
                </div>
                <div class="route">
                    <div class="left">
                        <p>From</p>
                        <div>
                            <img src="@/assets/logos/zksyncLogo.png" alt="">
                            <span>SpaceFi </span>
                            <span> 1.0</span>
                        </div>
                    </div>
                    <div class="center">
                        <i class="el-icon-right"></i>
                    </div>
                    <div class="right">
                        <p>To</p>
                        <div>
                            <img src="@/assets/logos/zksyncLogo.png" alt="">
                            <span>SpaceFi </span>
                            <span> 2.0</span>
                        </div>
                    </div>
                </div>
                <div class="tabItems">
                    <div v-if="actIndex == 0" class="nftBox">
                        <p  @click="messageShow = true;getNFTData()">
                            <span>Select an NFT :</span>
                            <span>
                                <i v-for="(item,index) in selectNames" :key="index">
                                    {{item}}{{index == selectNames.length-1 ? '' : ','}}
                                </i>
                            </span>
                            <i class="el-icon-caret-bottom"></i>
                        </p>
                    </div>
                    <div v-if="actIndex == 1" class="spaceBox">
                        <div class="balance">
                            Balance: {{SPACEBalance}} SPACE
                        </div>
                        <p>
                            <span>Send :</span>
                            <input type="number" v-model="sendSpaceNum" placeholder="Please input">
                            <span class="max" @click="sendSpaceNum = SPACEBalance">max</span>
                        </p>
                    </div>
                    <div v-if="actIndex == 2" class="xspaceBox">
                        <div class="balance">
                            Balance: {{xSPACEBalance}} xSPACE
                        </div>
                        <p>
                            <span>Send :</span>
                            <input type="number" v-model="sendxSpaceNum" placeholder="Please input">
                            <span class="max" @click="sendxSpaceNum = xSPACEBalance">max</span>
                        </p>
                    </div>
                </div>
                <div class="btns">
                    <button v-if="actIndex == 0" :class="selectNFTIds.length == 0 ? '' : 'action'" @click="migrationNFT">Migration <v-LoadingWarp v-if="migrationNFTLoading" style="margin-left: 5px;" :imgSize="1"></v-LoadingWarp></button>
                    <button v-if="actIndex == 1" :class="!sendSpaceNum ? '' : 'action'" @click="migrationStarToken">Migration <v-LoadingWarp v-if="migrationSPACELoading" style="margin-left: 5px;" :imgSize="1"></v-LoadingWarp></button>
                    <button v-if="actIndex == 2" :class="!sendxSpaceNum ? '' : 'action'" @click="migrationxStarToken">Migration <v-LoadingWarp v-if="migrationxSPACELoading" style="margin-left: 5px;" :imgSize="1"></v-LoadingWarp></button>
                </div>
            </div>
        </div>
        <div class="zksync">
            <div class="center connectBoxW">
                <div>
                    <div class="titlebox">
                        <h1>
                            <img src="@/assets/logos/bridgeZKSYNC.png" alt="">
                            <!-- <span>zkSync Era</span> -->
                        </h1>
                        <p>— — Bridges for zkSync Era — —</p>
                    </div>
                    <div class="listbox">
                        <ul>
                            <li v-for="(item,index) in list.zksync" :key="index" @click="goDetail(item.link)">
                                <p class="title">{{item.name}}</p>
                                <p class="network">{{item.network}}</p>
                                <div>
                                    <img :src="item.img" alt="">
                                    <a :href="item.link" target="_blank" rel="noopener noreferrer">
                                        Open {{item.name}}
                                        <i class="el-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll">
            <div class="center connectBoxW">
                <div>
                    <div class="titlebox">
                        <h1>
                            <img src="@/assets/logos/bridgeSCROLL.png" alt="">
                            <!-- <span>Scroll</span> -->
                        </h1>
                        <p>— — Bridges for Scroll — —</p>
                    </div>
                    <div class="listbox">
                        <ul>
                            <li v-for="(item,index) in list.scroll" :key="index" @click="goDetail(item.link)">
                                <p class="title">{{item.name}}</p>
                                <p class="network">{{item.network}}</p>
                                <div>
                                    <img :src="item.img" alt="">
                                    <a :href="item.link" target="_blank" rel="noopener noreferrer">
                                        Open {{item.name}}
                                        <i class="el-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="evmos">
            <div class="center connectBoxW">
                <div>
                    <div class="titlebox">
                        <h1>
                            <img src="@/assets/logos/bridgeEVMOS.png" alt="">
                            <!-- <span>Evmos</span> -->
                        </h1>
                        <p>— — Bridges for Evmos — —</p>
                    </div>
                    <div class="listbox">
                        <ul>
                            <li v-for="(item,index) in list.evmos" :key="index" @click="goDetail(item.link)">
                                <p class="title">{{item.name}}</p>
                                <p class="network">{{item.network}}</p>
                                <div>
                                    <img :src="item.img" alt="">
                                    <a :href="item.link" target="_blank" rel="noopener noreferrer">
                                        Open {{item.name}}
                                        <i class="el-icon-arrow-right"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <v-Footer></v-Footer>
            </div>
            
        </div>

        <div class="farmMessageBox" v-if="messageShow" @click="messageShow = false;">
            <div class="connectBoxW">
                <div class="warp" @click.stop>
                    <div class="selectannft">
                        <div class="titlebox">
                            <h1>
                                Select an NFT
                                <i class="el-icon-close" @click="messageShow = false;"></i>
                            </h1>
                        </div>
                        <div class="inputBox">
                            <input type="text" v-model="searchName" placeholder="search by NFT name" @keyup.enter="searchNFT()">
                            <i v-if="searchName != ''" class="el-icon-circle-close" style="margin-right:10px;" @click="searchName = '';searchNFT()"></i>
                            <i class="el-icon-search" @click="searchNFT()"></i>
                        </div>
                        <div class="selectAll"  v-if="nftsList.length != 0">
                            <!-- <p @click="selectAllBtn">
                                <i :class="selectAll ? 'el-icon-success' : 'el-icon-circle-check'"></i>
                                Select all
                            </p> -->
                            <i class="el-icon-warning"></i>&nbsp;Migrate up to 5 nft in a single pass
                        </div>
                        <div class="nfts">
                            <div v-if="nftsLoading && nftsList.length == 0">
                                 <v-LoadingWarp :imgSize="3"></v-LoadingWarp>
                            </div>
                            <p v-if="!nftsLoading && nftsList.length == 0"><img src="../assets/logos/spacefiFFF.png" alt=""> No data yet</p>
                            <ul v-if="!nftsLoading && nftsList.length != 0">
                                <li v-for="(item,index) in nftsList" :key="index" @click="selectItem(index)">
                                    <img :src="item.nftUrl" alt="">
                                    <i :class="item.select ? 'el-icon-success' : 'el-icon-circle-check'"></i>
                                </li>
                                <li v-if="nftsList.length%3 > 0"></li>
                                <li v-if="nftsList.length%3 > 1"></li>
                            </ul>
                        </div>
                        <div class="btnbox" v-if="nftsList.length != 0">
                            <div class="confirmbtn" @click="messageShow = false;selectOk()">OK</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import Web3 from 'web3';
import { 
    judgeNetwork, getAllowance, setApprove,
    nftBalanceOf, tokenOfOwnerByIndex, cateURI, MigrateNFT,
    getBalanceOf, 
    MigrateStarToken, MigrateXStarToken
} from '../utils/space.js'
export default {
    name: "Bridge",
    data() {
        return {
            tokenSymbol:'',xtokenSymbol:'',
            actIndex: 0,
            messageShow:false,
            nftsLoading:true,
            selectNames:[],
            selectNFTIds:[],
            searchName:'',
            migrationNFTLoading:false,
            migrationSPACELoading:false,
            migrationxSPACELoading:false,
            nfts:[],
            nftsList:[],
            selectAll:false,
            SPACEBalance:0,sendSpaceNum:null,
            xSPACEBalance:0,sendxSpaceNum:null,
            list:{
                zksync:[
                    {
                        name:'Orbiter',
                        network:'zkSync Era / Ethereum / BNB Chain / Polygon / Arbitrum / Optimism / Starknet / Loopring',
                        img:require('@/assets/Bridge/Orbiter.png'),
                        link:'https://www.orbiter.finance/'
                    },{
                        name:'Rhino',
                        network:'zkSync Era / Scroll / Ethereum / Arbitrum One / BNB Smart Chain / Base / Linea / Optimism / Polygon',
                        img:require('@/assets/Bridge/rhino.png'),
                        link:'https://app.rhino.fi/bridge/'
                    },{
                        name:'Celer',
                        network:'zkSync Era / Ethereum / BNB Chain / Polygon / Arbitrum / Optimism / Aptos',
                        img:require('@/assets/Bridge/Celer.png'),
                        link:'https://cbridge.celer.network/56/324/BNB'
                    },{
                        name:'Bungee',
                        network:'zkSync Era / Ethereum / BNB Chain / Polygon / Arbitrum / Optimism',
                        img:require('@/assets/Bridge/Bungee.png'),
                        link:'https://www.bungee.exchange/'
                    },{
                        name:'XY Finance',
                        network:'zkSync Era / Ethereum / BNB Chain / Polygon / Arbitrum / Optimism',
                        img:require('@/assets/Bridge/XYFinance.png'),
                        link:'https://app.xy.finance/'
                    },{
                        name:'OmniBTC',
                        network:'zkSync Era / Ethereum / BNB Chain / Polygon / Arbitrum / Optimism',
                        img:require('@/assets/Bridge/OmniBTC.png'),
                        link:'https://app.omnibtc.finance/swap'
                    },
                ],
                scroll:[
                    {
                        name:'Rhino',
                        network:'Scroll / zkSync Era / Ethereum / Arbitrum One / BNB Smart Chain / Base / Linea / Optimism / Polygon',
                        img:require('@/assets/Bridge/rhino.png'),
                        link:'https://app.rhino.fi/bridge/'
                    },{
                        name:'Orbiter',
                        network:'Scroll / zkSync Era / Ethereum / BNB Chain / Polygon / Arbitrum / Optimism / Starknet / Loopring',
                        img:require('@/assets/Bridge/Orbiter.png'),
                        link:'https://www.orbiter.finance/'
                    },{
                        name:'Owlto',
                        network:'Scroll / Ethereum / Starknet / Base / Linea / zkSync Era / Arbitrum / Optimism / Polygon zkEVM / Zora / Kroma',
                        img:require('@/assets/Bridge/Owlto.png'),
                        link:'https://owlto.finance/bridge'
                    },
                ],
                evmos:[
                    {
                        name:'Axelar',
                        network:'Evmos / Ethereum / BNB / Cosmos / Osmosis / Arbitrum / Polygon',
                        img:require('@/assets/Bridge/Axelar.png'),
                        link:'https://satellite.money/'
                    },{
                        name:'Gravity',
                        network:'Evmos / Ethereum / Cosmos / Osmosis',
                        img:require('@/assets/Bridge/Gravity.png'),
                        link:'https://www.gravitybridge.net/'
                    },{
                        name:'Celer',
                        network:'Evmos / BNB / Avalanche / Fantom',
                        img:require('@/assets/Bridge/Celer.png'),
                        link:'https://cbridge.celer.network/'
                    },
                ]
            }
        }
    },          
    created() { },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.nftInfoList = window.nftImgData.cardListMainnet
        this.migrateAddress = window.contractAddr.migrateAddress
        this.oldTokenAddress = window.contractAddr.oldTokenAddress
        this.oldxTokenAddress = window.contractAddr.oldxTokenAddress
        this.oldnftAddress = window.contractAddr.oldnftAddress
        if(this.userAddress){
            getBalanceOf(this.oldTokenAddress,this.userAddress).then(res => {
                this.SPACEBalance = this.$BigNumber(res).div(Math.pow(10,18))
            }).catch(err => {
                console.log(err)
            })
            getBalanceOf(this.oldxTokenAddress,this.userAddress).then(res => {
                this.xSPACEBalance = this.$BigNumber(res).div(Math.pow(10,18))
            }).catch(err => {
                console.log(err)
            })
        }
    },
    methods: {
        getNFTData(){
            this.nftsLoading = true
            this.nfts = []
            this.nftsList = []
            if(this.userAddress){
                nftBalanceOf(this.oldnftAddress,this.userAddress).then(nftBalanceOf => {
                    //console.log(nftBalanceOf)
                    if(nftBalanceOf == 0){
                        this.nftsLoading = false
                        return false
                    }
                    for (let i = 0; i < nftBalanceOf; i++) {
                        tokenOfOwnerByIndex(this.oldnftAddress,i,this.userAddress).then(NFTId => {
                            cateURI(this.oldnftAddress,NFTId).then(nftUrl => {
                                //console.log(NFTId,nftUrl)
                                var obj = {
                                    NFTId,
                                    nftUrl,
                                    select: false
                                }
                                this.selectNFTIds.forEach((item,index) => {
                                    if(item == NFTId){
                                        obj.select = true
                                    }
                                })
                                this.nftInfoList.forEach((item,index) => {
                                    if (item.cid == nftUrl.split('/')[3]) {
                                        obj.name = item.name
                                    }
                                })
                                this.nfts.push(obj)
                                this.dataSort(this.nfts)
                                //console.log(this.nfts)
                                this.nftsList = this.nfts
                                this.nftsLoading = false
                            })
                        })
                    }
                })
            } else {
                this.nftsLoading = false
            }
        },
        selectItem(index){
            //console.log(index)
            var num = 0
            this.nftsList.forEach((item,index) => {
                if(item.select){
                    num += 1
                }
            })
            console.log(num)
            if( num >= 5 && !this.nftsList[index].select ){
                this.$message({
                    type:"warning",
                    message:"Migrate up to 5 nft in a single pass"
                })
                return false
            }
            this.$set(this.nftsList[index],'select',!this.nftsList[index].select)
            this.selectAll = this.nftsList.every((item,index) => {
                return item.select
            })
            //console.log(aaa)

        },
        selectOk(){
            this.selectNames = []
            this.selectNFTIds = []
            this.nftsList.forEach((item,index) => {
                if(item.select){
                    this.selectNames.push(item.name)
                    this.selectNFTIds.push(item.NFTId)
                }
            })
        },
        selectAllBtn(){
            this.selectAll = !this.selectAll
            this.nftsList.forEach((item,index) => {
                item.select = this.selectAll
            })
        },
        migrationNFT(){
            if(this.selectNFTIds.length == 0){ return false }
            if(this.migrationNFTLoading){ return false }
            this.migrationNFTLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.migrationNFTLoading = false
                    return false
                }
                console.log(this.selectNFTIds)
                MigrateNFT(this.migrateAddress,this.selectNFTIds,this.userAddress).then(res => {
                    this.migrationNFTLoading = false
                    this.selectNames = []
                    this.selectNFTIds = []
                    this.$message({ type: 'success', message: 'Migrate success' });
                }).catch(error => {
                    console.log(error)
                    this.migrationNFTLoading = false
                })
            })
        },
        migrationStarToken(){
            if(!this.sendSpaceNum){ 
                this.$message({ message:'Please input', type:'warning' })
                return false 
            }
            if(Number(this.SPACEBalance) < Number(this.sendSpaceNum)){ 
                this.$message({ message:'Insufficient Balance', type:'warning' })
                return false 
            }
            if(this.migrationSPACELoading){ return false }
            this.migrationSPACELoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.migrationSPACELoading = false
                    return false
                }
                getAllowance(this.oldTokenAddress ,this.migrateAddress, this.userAddress).then(res => {
                    //console.log(res)
                    if (Number(res/Math.pow(10,18))>Number(this.sendSpaceNum)) {
                        this.tokenMigrateStar()
                    } else {
                        setApprove(this.oldTokenAddress,this.migrateAddress,this.userAddress ).then(res => {
                            this.tokenMigrateStar()
                        },error => {
                            this.migrationSPACELoading = false
                        }).catch(error => {
                            this.migrationSPACELoading = false
                        })
                    }
                })
            })
        },
        tokenMigrateStar(){
            var _sendSpaceNum = Web3.utils.toBN( this.$BigNumber(this.sendSpaceNum).multipliedBy(this.$BigNumber(Math.pow(10,18))))
            MigrateStarToken(this.migrateAddress,_sendSpaceNum,this.userAddress).then(res => {
                this.migrationSPACELoading = false
                getBalanceOf(this.oldTokenAddress,this.userAddress).then(res => {
                    this.SPACEBalance = res/Math.pow(10,18)
                })
                this.sendSpaceNum = null
                this.$message({ type: 'success', message: 'Migrate success' });
            }).catch(error => {
                this.migrationSPACELoading = false
            })
        },
        migrationxStarToken(){
            if(!this.sendxSpaceNum){ 
                this.$message({ message:'Please input', type:'warning' })
                return false 
            }
            if(Number(this.xSPACEBalance) < Number(this.sendxSpaceNum)){ 
                this.$message({ message:'Insufficient Balance', type:'warning' })
                return false 
            }
            if(this.migrationxSPACELoading){ return false }
            this.migrationxSPACELoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.migrationxSPACELoading = false
                    return false
                }
                getAllowance(this.oldxTokenAddress ,this.migrateAddress, this.userAddress).then(res => {
                    //console.log(res)
                    if (Number(res/Math.pow(10,18))>Number(this.sendxSpaceNum)) {
                        this.tokenMigratexStar()
                    } else {
                        setApprove(this.oldxTokenAddress,this.migrateAddress,this.userAddress ).then(res => {
                            this.tokenMigratexStar()
                        },error => {
                            this.migrationxSPACELoading = false
                        }).catch(error => {
                            this.migrationxSPACELoading = false
                        })
                    }
                })
            })
        },
        tokenMigratexStar(){
            var _sendxSpaceNum = Web3.utils.toBN( this.$BigNumber(this.sendxSpaceNum).multipliedBy(this.$BigNumber(Math.pow(10,18))))
            MigrateXStarToken(this.migrateAddress,_sendxSpaceNum,this.userAddress).then(res => {
                this.migrationxSPACELoading = false
                getBalanceOf(this.oldxTokenAddress,this.userAddress).then(res => {
                    this.xSPACEBalance = res/Math.pow(10,18)
                })
                this.sendxSpaceNum = null
                this.$message({ type: 'success', message: 'Migrate success' });
            }).catch(error => {
                this.migrationxSPACELoading = false
            })
        },
        searchNFT(){
            //console.log(12313)
            if(this.searchName == ''){
                this.nftsList = this.nfts
                return
            }
            this.nftsList = []
            this.nftsLoading = true
            this.nfts.forEach((item,index) => {
                //console.log(item)
                if(this.searchName.toLowerCase() == item.name.toLowerCase() || this.searchName == item.NFTId){
                    this.nftsList.push(item)
                    this.$forceUpdate()
                }
            })
            this.nftsLoading = false
        },
        
        goDetail(link){
            window.open(link,'_block')
        },
        dataSort(arr){
            arr.sort((a ,b) => {
                if (Number(a.NFTId) > Number(b.NFTId)) {
                    return -1
                } else if (Number(a.NFTId) < Number(b.NFTId)) {
                    return 1
                }
            })
        },
    }
}
</script>